

















































































































































































































































































































































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'TermsAndConditions',
  created() {
    this.$store.dispatch('updateHeaderBgColor', undefined);
    this.$store.dispatch('updateHeaderAsApp', false);
  },
});
