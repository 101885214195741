






import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import LeaningPlan from '@/components/sections/LeaningPlan.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    LeaningPlan,
  },
  computed: mapGetters(['displayRegistrationList']),
  created() {
    this.$store.dispatch('updateHeaderBgColor', 'black');
    this.$store.dispatch('updateHeaderAsApp', false);
  },
});
