import LeaningPlan from '@/types/LearningPlan';

const LeaningPlan: LeaningPlan = {
  1: {
    title: 'Física 1 - Mecânica I - Cinemática, Dinâmica e Rotações',
    children: {
      1: {
        title: 'Unidades e grandezas Físicas',
        children: {
          1.1: {
            title: 'Plano da aula',
          },
          1.2: {
            title: 'Apostila Introdução à Física e Medição',
          },
          1.3: {
            title: 'Videoaula - Introdução. Grandezas na Física',
          },
          1.4: {
            title: 'Videoaula - O Sistema Internacional de Unidades',
          },
          1.5: {
            title: 'Videoaula - Conversão de Unidades',
            children: {
              '1.5.1': {
                title: 'Exemplo de transformação de unidades',
              },
            },
          },
          1.6: {
            title: 'Videoaula - Unidade de comprimento',
            children: {
              '1.6.1': {
                title: 'Exemplo de transformação de unidade de comprimento',
              },
            },
          },
          1.7: {
            title: 'Videoaula - Unidade de tempo',
            children: {
              '1.7.1': {
                title: 'Exemplo de transformações de unidades de tempo',
              },
              '1.7.2': {
                title: 'Exemplo de transformações de unidades de tempo',
              },
            },
          },
          1.8: {
            title: 'Videoaula - Unidade de massa',
            children: {
              '1.8.1': {
                title: 'Exemplo de Transformações de unidades de massa',
              },
              '1.8.2': {
                title: 'Exemplo de Transformações de unidades de massa',
              },
              '1.8.3': {
                title: 'Exemplo de Transformações de unidades de massa',
              },
            },
          },
          1.9: {
            title: 'Experimentos de Medição de grandezas',
          },
          '1.10': {
            title: 'Problemas com resolução de Unidades e grandezas físicas',
          },
          1.11: {
            title: 'Simulações interativas',
          },
          1.12: {
            title: 'Resumo de Unidades e grandezas Física',
          },
        },
      },
      2: {
        title: 'Movimento Retilíneo',
        children: {
          2.1: {
            title: 'Plano da aula',
          },
          2.2: {
            title: 'Apostila Movimento Retilíneo',
          },
          2.3: {
            title: 'Videoaula - Movimento em 1D',
          },
          2.4: {
            title: 'Videoaula - Posição e deslocamento',
          },
          2.5: {
            title: 'Videoaula - Velocidade média e escalar média',
            children: {
              '2.5.1': {
                title: 'Exemplo de velocidade média',
              },
              '2.5.2': {
                title: 'Segundo exemplo de velocidade média',
              },
              '2.5.3': {
                title: 'Terceiro exemplo de velocidade média',
              },
              '2.5.4': {
                title: 'Quarto exemplo de velocidade média',
              },
            },
          },
          2.6: {
            title: 'Videoaula - Velocidade instantânea e escalar instantânea',
          },
          2.7: {
            title: 'Videoaula - aceleração',
            children: {
              '2.7.1': {
                title: 'Exemplo de velocidade e aceleração instantânea',
              },
            },
          },
          2.8: {
            title: 'Videoaula - aceleração constante um caso especial',
          },
          2.9: {
            title: 'Videoaula - Mais sobre aceleração constante',
          },
          '2.10': {
            title: 'Videoaula - Aceleração na Queda Livre',
            children: {
              '2.10.1': {
                title: 'Exemplo de queda livre',
              },
            },
          },
          2.11: {
            title: 'Videoaula - Análise Gráfica',
            children: {
              '2.11.1': {
                title: 'Exemplo de análise gráfica',
              },
            },
          },
          2.12: {
            title: 'Experimentos de Movimento Retilíneo',
          },
          2.13: {
            title: 'Simulações interativas Movimentos',
          },
          2.14: {
            title: 'Problemas com resolução de Movimento Retilíneo',
          },
        },
      },
      3: {
        title: 'Vetores',
        children: {
          3.1: {
            title: 'Plano da aula',
          },
          3.2: {
            title: 'Apostila de vetores',
          },
          3.3: {
            title: 'Videoaula - O que são vetores?',
          },
          3.4: {
            title: 'Videoaula - Vetores e escalares',
          },
          3.5: {
            title: 'Videoaula - Soma de vetores',
          },
          3.6: {
            title: 'Videoaula - Componentes de vetores',
            children: {
              '3.6.1': {
                title: 'Exemplo de Componentes de vetores',
              },
            },
          },
          3.7: {
            title: 'Videoaula - Vetores Unitários',
          },
          3.8: {
            title: 'Videoaula - Soma de vetores a partir das componente',
            children: {
              '3.8.1': {
                title: 'Exemplo de Soma de vetores',
              },
              '3.8.2': {
                title: 'Exemplo de Soma de vetores 2',
              },
              '3.8.3': {
                title: 'Exemplo de Soma de vetores 3',
              },
            },
          },
          3.9: {
            title: 'Videoaula - Vetores e as leis Físicas',
          },
          '3.10': {
            title: 'Videoaula - Produto de vetores',
            children: {
              '3.10.1': {
                title: 'Exemplo de produto escalar',
              },
              '3.10.2': {
                title: 'Exemplo de produto vetorial',
              },
            },
          },
          3.11: {
            title: 'Experimentos de Vetores',
          },
          3.12: {
            title: 'Problemas com resolução de vetores',
          },
          3.13: {
            title: 'Simulações interativas vetores',
          },
        },
      },
      4: {
        title: 'Movimentos em duas ou três dimensões',
        children: {
          4.1: {
            title: 'Plano da aula',
          },
          4.2: {
            title: 'Apostila de movimentos em 2D e 3D',
          },
          4.3: {
            title: 'Videoaula - O que é movimento em 2 ou 3 dimensões?',
          },
          4.4: {
            title: 'Videoaula - Posição e deslocamento',
            children: {
              '4.4.1': {
                title: 'Exemplo de Posição e deslocamento',
              },
            },
          },
          4.5: {
            title: 'Videoaula - Velocidade média e instantânea',
            children: {
              '4.5.1': {
                title: 'Exemplo de Velocidade instantânea',
              },
            },
          },
          4.6: {
            title: 'Videoaula - Aceleração média e instantânea',
            children: {
              '4.6.1': {
                title: 'Exemplo de Velocidade média e instantânea',
              },
            },
          },
          4.7: {
            title: 'Videoaula - Movimento balístico',
          },
          4.8: {
            title: 'Videoaula - Análise do Movimento balístico',
            children: {
              '4.8.1': {
                title: 'Exemplo de Movimento balístico',
              },
              '4.8.2': {
                title: 'Exemplo de Movimento balístico 2',
              },
            },
          },
          4.9: {
            title: 'Videoaula - Movimento Circular e Uniforme',
            children: {
              '4.9.1': {
                title: 'Exemplo de Movimento Circular e Uniforme',
              },
            },
          },
          '4.10': {
            title: 'Videoaula - Movimento Relativo em uma dimensão',
          },
          4.11: {
            title: 'Videoaula - Movimento Relativo em duas dimensões',
            children: {
              '4.11.1': {
                title: 'Exemplo de Movimento Relativo',
              },
            },
          },
          4.12: {
            title: 'Experimentos de Movimento em 2 ou 3 dimensões',
          },
          4.13: {
            title: 'Problemas com resolução de movimentos em 2D e 3D',
          },
          4.14: {
            title: 'Simulações interativas movimentos em 2D e 3D',
          },
        },
      },
      5: {
        title: 'Leis de Newton do movimentos',
        children: {
          5.1: {
            title: 'Plano da aula',
          },
          5.2: {
            title: 'Apostila de Leis de Newton',
          },
          5.3: {
            title: 'Videoaula - Mecânica newtoniana',
          },
          5.4: {
            title: 'Videoaula - A Primeira Lei de Newton Inércia',
          },
          5.5: {
            title: 'Videoaula - Força',
          },
          5.6: {
            title: 'Videoaula - Massa',
          },
          5.7: {
            title: 'Videoaula - A segunda Lei de Newton',
            children: {
              '5.7.1': {
                title: 'Exemplo de segunda Lei de Newton',
              },
              '5.7.2': {
                title: 'Exemplo de segunda Lei de Newton 2',
              },
            },
          },
          5.8: {
            title: 'Videoaula - Algumas forças especiais',
            children: {
              '5.8.1': {
                title: 'Exemplo de Algumas forças especiais',
              },
            },
          },
          5.9: {
            title: 'Videoaula - A terceira Lei de Newton',
          },
          '5.10': {
            title: 'Videoaula - Aplicações das Leis de Newton',
            children: {
              '5.10.1': {
                title: 'Exemplo de Aplicações das Leis de Newton',
              },
              '5.10.2': {
                title: 'Exemplo de Aplicações das Leis de Newton 2',
              },
              '5.10.3': {
                title: 'Exemplo de Aplicações das Leis de Newton 3',
              },
              '5.10.4': {
                title: 'Exemplo de Aplicações das Leis de Newton 4',
              },
              '5.10.5': {
                title: 'Exemplo de Aplicações das Leis de Newton 5',
              },
            },
          },
          5.11: {
            title: 'Experimentos de Leis de Newton',
          },
          5.12: {
            title: 'Problemas com resolução de Lei de Newton',
          },
          5.13: {
            title: 'Simulações interativas Leis de Newton',
          },
        },
      },
      6: {
        title: 'Aplicações das Leis de Newton - Atrito',
        children: {
          6.1: {
            title: 'Plano da aula',
          },
          6.2: {
            title: 'Apostila de força de atrito',
          },
          6.3: {
            title: 'Videoaula - Introdução ao atrito',
          },
          6.4: {
            title: 'Videoaula - Atrito',
          },
          6.5: {
            title: 'Videoaula - Propriedades do Atrito',
            children: {
              '6.5.1': {
                title: 'Exemplo de força de atrito',
              },
              '6.5.2': {
                title: 'Exemplo de força de atrito 2',
              },
              '6.5.3': {
                title: 'Exemplo de força de atrito 3',
              },
            },
          },
          6.6: {
            title: 'Videoaula - Força de Arrasto e velocidade terminal',
            children: {
              '6.6.1': {
                title: 'Exemplo de força de arrasto',
              },
            },
          },
          6.7: {
            title: 'Videoaula - Força centrípeta',
            children: {
              '6.7.1': {
                title: 'Exemplo de força de atrito Força centrípeta',
              },
              '6.7.2': {
                title: 'Exemplo de força de atrito Força centrípeta',
              },
            },
          },
          6.8: {
            title: 'Experimentos de força de atrito',
          },
          6.9: {
            title: 'Problemas resolvidos de aplicações de Leis de Newton',
          },
          '6.10': {
            title: 'Simulações interativas força de atrito',
          },
        },
      },
      7: {
        title: 'Trabalho e Energia Cinética',
        children: {
          7.1: {
            title: 'Plano da aula',
          },
          7.2: {
            title: 'Apostila de Trabalho e Energia Cinética',
          },
          7.3: {
            title: 'Videoaula - Introdução Trabalho e energia',
          },
          7.4: {
            title: 'Videoaula - Energia Cinética',
            children: {
              '7.4.1': {
                title: 'Exemplo de Energia cinética',
              },
            },
          },
          7.5: {
            title: 'Videoaula - Trabalho',
            children: {
              '7.5.1': {
                title: 'Exemplo de Trabalho',
              },
            },
          },
          7.6: {
            title: 'Videoaula - Trabalho e energia cinética',
            children: {
              '7.6.1': {
                title: 'Exemplo de Trabalho e Energia cinética',
              },
              '7.6.2': {
                title: 'Exemplo de Trabalho e Energia cinética 2',
              },
            },
          },
          7.7: {
            title: 'Videoaula - Trabalho realizado pela força gravitacional',
            children: {
              '7.7.1': {
                title: 'Exemplo de Trabalho realizado pela força gravitacional',
              },
            },
          },
          7.8: {
            title: 'Videoaula - Trabalho realizado pela força elástica',
          },
          7.9: {
            title: 'Videoaula - Trabalho realizado por uma força variável',
            children: {
              '7.9.1': {
                title: 'Exemplo de Trabalho realizado por uma força variável',
              },
              '7.9.2': {
                title: 'Exemplo de Trabalho realizado por uma força variável 2',
              },
            },
          },
          '7.10': {
            title: 'Videoaula - Potência',
            children: {
              '7.10.1': {
                title: 'Exemplo de Potência',
              },
            },
          },
          7.11: {
            title: 'Experimentos de Trabalho e energia cinética',
          },
          7.12: {
            title: 'Problemas resolvidos de Trabalho e energia cinética',
          },
          7.13: {
            title: 'Simulações interativas Trabalho e energia cinética',
          },
        },
      },
      8: {
        title: 'Energia Potencial e Conservação de Energia',
        children: {
          8.1: {
            title: 'Plano da aula',
          },
          8.2: {
            title: 'Apostila de Energia Potencial e Conservação de Energia',
          },
          8.3: {
            title: 'Videoaula - Energia mecânica introdução',
          },
          8.4: {
            title: 'Videoaula - Trabalho e energia potencial',
          },
          8.5: {
            title:
              'Videoaula - Independência da trajetória para o trabalho de forças conservativas',
          },
          8.6: {
            title: 'Videoaula - Cálculo da energia potencial',
            children: {
              '8.6.1': {
                title: 'Exemplo de Cálculo da energia potencial',
              },
            },
          },
          8.7: {
            title: 'Videoaula - Energia mecânica',
            children: {
              '8.7.1': {
                title: 'Exemplo de  Energia mecânica',
              },
              '8.7.2': {
                title: 'Exemplo de  Energia mecânica 2',
              },
              '8.7.3': {
                title: 'Exemplo de  Energia mecânica 3',
              },
              '8.7.4': {
                title: 'Exemplo de  Energia mecânica 4',
              },
            },
          },
          8.8: {
            title: 'Videoaula - Diagramas de energia',
            children: {
              '8.8.1': {
                title: 'Exemplo de  Diagramas de energia',
              },
            },
          },
          8.9: {
            title: 'Videoaula - Trabalho de uma força externa sobre o sistema',
          },
          '8.10': {
            title: 'Videoaula - Conservação de energia total',
            children: {
              '8.10.1': {
                title: 'Exemplo de Conservação de energia total',
              },
              '8.10.2': {
                title: 'Exemplo de Conservação de energia total',
              },
            },
          },
          8.11: {
            title: 'Experimentos de Conservação de energia',
          },
          8.12: {
            title: 'Problemas resolvidos de energia mecânica',
          },
          8.13: {
            title: 'Simulações interativas Trabalho e energia cinética',
          },
        },
      },
      9: {
        title: 'Centro de massa e Conservação de momentum linear',
        children: {
          9.1: {
            title: 'Plano da aula',
          },
          9.2: {
            title: 'Apostila de Centro de massa e Conservação de momentum linear',
          },
          9.3: {
            title: 'Videoaula - Centro de massa',
          },
          9.4: {
            title: 'Videoaula - Cálculo do centro de massa',
            children: {
              '9.4.1': {
                title: 'Exemplo de Cálculo do centro de massa',
              },
              '9.4.2': {
                title: 'Exemplo de Cálculo do centro de massa 2',
              },
            },
          },
          9.5: {
            title: 'Videoaula - Segunda lei de Newton para um sistema de partículas',
          },
          9.6: {
            title: 'Videoaula - Momentum (quantidade de movimento) linear',
            children: {
              '9.6.1': {
                title: 'Exemplo de Momentum linear',
              },
              '9.6.2': {
                title: 'Exemplo de Momentum linear',
              },
            },
          },
          9.7: {
            title: 'Videoaula - Momentum linear de um sistema de partículas',
          },
          9.8: {
            title: 'Videoaula - Impulso',
          },
          9.9: {
            title: 'Videoaula - Conservação de momentum linear de um sistema de partículas',
            children: {
              '9.9.1': {
                title: 'Exemplo de Conservação de momentum de um sistema',
              },
              '9.9.2': {
                title: 'Exemplo de Conservação de momentum de um sistema 2',
              },
            },
          },
          '9.10': {
            title: 'Videoaula - Momentum e Energia cinética das colisões',
          },
          9.11: {
            title: 'Videoaula - Colisões inelásticas',
            children: {
              '9.11.1': {
                title: 'Exemplo de Colisões inelásticas',
              },
            },
          },
          9.12: {
            title: 'Videoaula - Colisões elásticas em uma dimensão',
            children: {
              '9.12.1': {
                title: 'Exemplo de Colisões elásticas',
              },
            },
          },
          9.13: {
            title: 'Videoaula - Colisões em duas dimensões',
            children: {
              '9.13.1': {
                title: 'Exemplo de Colisões em duas dimensões',
              },
            },
          },
          9.14: {
            title: 'Videoaula - sistemas de massas variáveis - foguetes',
          },
          9.15: {
            title: 'Experimentos de Conservação de momentum linear do sistema',
          },
          9.17: {
            title: 'Problemas resolvidos de Centro de massa e momentum linear',
          },
          9.18: {
            title: 'Simulações interativas Centro de massa e conservação de momentum linear',
          },
        },
      },
      10: {
        title: 'Rotação de corpos rígidos',
        children: {
          10.1: {
            title: 'Plano da aula',
          },
          10.2: {
            title: 'Apostila de Rotação de corpos rígidos',
          },
          10.3: {
            title: 'Videoaula - O que é Física Rotação e translação',
          },
          10.4: {
            title: 'Videoaula - Posição velocidade e aceleração angulares',
          },
          10.5: {
            title: 'Videoaula - Aceleração angular constante',
            children: {
              '10.5.1': {
                title: 'Exemplo de cinemática rotacional',
              },
              '10.5.2': {
                title: 'Exemplo de cinemática rotacional 2',
              },
              '10.5.3': {
                title: 'Exemplo de cinemática rotacional 3',
              },
            },
          },
          10.6: {
            title: 'Videoaula - Energia cinética de rotação',
          },
          10.7: {
            title: 'Videoaula - momento de inércia',
            children: {
              '10.7.1': {
                title: 'Exemplo de momento de inércia',
              },
              '10.7.2': {
                title: 'Exemplo de momento de inércia 2',
              },
            },
          },
          10.8: {
            title: 'Videoaula - Torque',
          },
          10.9: {
            title: 'Videoaula - 2a lei de Newton rotação',
            children: {
              '10.9.1': {
                title: 'Exemplo de 2a lei de Newton rotação',
              },
            },
          },
          '10.10': {
            title: 'Videoaula - Trabalho e energia nas rotações',
            children: {
              '10.10.1': {
                title: 'Exemplo de Trabalho e energia nas rotações',
              },
              '10.10.2': {
                title: 'Exemplo de Trabalho e energia nas rotações 2',
              },
            },
          },
          10.11: {
            title: 'Experimentos de rotação de corpos rígidos',
          },
          10.12: {
            title: 'Problemas resolvidos de rotações',
          },
          10.13: {
            title: 'Simulações interativas Rotação de corpos rígidos',
          },
        },
      },
      11: {
        title: 'Rolamento',
        children: {
          11.1: {
            title: 'Plano da aula',
          },
          11.2: {
            title: 'Apostila de Rolamento',
          },
          11.3: {
            title: 'Videoaula - Rolamento',
          },
          11.4: {
            title: 'Videoaula - Rolamento composição de velocidades',
            children: {
              '11.4.1': {
                title: 'Exemplo de Rolamento',
              },
            },
          },
          11.5: {
            title: 'Videoaula - energia cinética do rolamento',
            children: {
              '11.5.1': {
                title: 'Exemplo de energia cinética do rolamento',
              },
              '11.5.2': {
                title: 'Exemplo de energia cinética do rolamento 2',
              },
            },
          },
          11.6: {
            title: 'Videoaula - Forças no rolamento',
          },
          11.7: {
            title: 'Videoaula - Ioiô',
            children: {
              '11.7.1': {
                title: 'Exemplo de Ioiô',
              },
            },
          },
          11.8: {
            title: 'Videoaula - Torque como um vetor',
            children: {
              '11.8.1': {
                title: 'Exemplo de Torque como um vetor',
              },
            },
          },
          11.9: {
            title: 'Videoaula - momento angular',
            children: {
              '11.9.1': {
                title: 'Exemplo de momento angular',
              },
            },
          },
          '11.10': {
            title: 'Videoaula - Segunda lei de Newton para rotações',
            children: {
              '11.10.1': {
                title: 'Exemplo de Segunda lei de Newton para rotações',
              },
            },
          },
          11.11: {
            title: 'Videoaula - Momento angular de um sistema de partículas',
            children: {
              '11.11.1': {
                title: 'Exemplo de Momento angular de um sistema de partículas',
              },
            },
          },
          11.12: {
            title: 'Videoaula - Momento angular de um corpo rígido',
          },
          11.13: {
            title: 'Videoaula - Lei de conservação de Momento angular',
            children: {
              '11.13.1': {
                title: 'Exemplo de Lei de conservação de Momento angular',
              },
            },
          },
          11.14: {
            title: 'Videoaula - Precessão de um giroscópio',
          },
          11.15: {
            title: 'Experimentos de rolamento',
          },
          11.16: {
            title: 'Problemas resolvidos de Rolamento',
          },
          11.17: {
            title: 'Simulações interativas Rolamento',
          },
        },
      },
    },
  },
  2: {
    title: 'Física 2 - Mecânica II - Gravitação, Estática, Ondulatória e Termologia',
    children: {
      12: {
        title: 'Equilíbrio e elasticidade',
        children: {
          12.1: {
            title: 'Plano da aula',
          },
          12.2: {
            title: 'Apostila de Equilíbrio e elasticidade',
          },
          12.3: {
            title: 'Videoaula - O que é Estática ?Vídeo',
          },
          12.4: {
            title: 'Videoaula - Equilíbrio estático e cinético',
          },
          12.5: {
            title: 'Videoaula - Condições de Equilíbrio',
          },
          12.6: {
            title: 'Videoaula - Centro de gravidade',
            children: {
              '12.6.1': {
                title: 'Exemplo - Centro de gravidade',
              },
            },
          },
          12.7: {
            title: 'Videoaula - Exemplos de equilíbrio estático',
            children: {
              '12.7.1': {
                title: 'Exemplo - Equilíbrio de forças',
              },
              '12.7.2': {
                title: 'Exemplo Régua em equilíbrio',
              },
              '12.7.3': {
                title: 'Exemplo - Viga',
              },
              '12.7.4': {
                title: 'Exemplo - Roda no degrau',
              },
            },
          },
          12.8: {
            title: 'Videoaula - Elasticidade',
          },
          12.9: {
            title: 'Videoaula - Elasticidade e plasticidade',
            children: {
              '12.9.1': {
                title: 'Exemplo - Cisalhamento',
              },
              '12.9.2': {
                title: 'Exemplo - Módulo de Young',
              },
            },
          },
          '12.10': {
            title: 'Experimentos de Equilíbrio e elasticidade',
          },
          12.11: {
            title: 'Problemas com resolução de Equilíbrio e elasticidade',
          },
          12.12: {
            title: 'Simulações interativas de Equilíbrio e Elasticidade',
          },
        },
      },
      13: {
        title: 'Gravitação Universal',
        children: {
          13.1: {
            title: 'Plano da aula',
          },
          13.2: {
            title: 'Apostila de Gravitação Universal',
          },
          13.3: {
            title: 'Videoaula - Gravitação Universal',
          },
          13.4: {
            title: 'Videoaula - Lei da Gravitação Universal',
            children: {
              '13.4.1': {
                title: 'Exemplo de força gravitacional',
              },
            },
          },
          13.5: {
            title: 'Videoaula - Princípio da superposição',
            children: {
              '13.5.1': {
                title: 'Exemplo princípio da superposição',
              },
            },
          },
          13.6: {
            title: 'Videoaula - Gravidade nas proximidades do planeta',
            children: {
              '13.6.1': {
                title: 'Exemplo gravidade nas proximidades dos planetas',
              },
            },
          },
          13.7: {
            title: 'Videoaula - Gravidade no interior da Terra',
            children: {
              '13.7.1': {
                title: 'Exemplo Gravidade no interior do planeta',
              },
            },
          },
          13.8: {
            title: 'Videoaula - Energia potencial gravitacional',
          },
          13.9: {
            title: 'Videoaula - 1a Lei de Kepler',
          },
          '13.10': {
            title: 'Videoaula - 2a Lei de Kepler',
          },
          13.11: {
            title: 'Videoaula - 3a Lei de Kepler',
          },
          13.12: {
            title: 'Videoaula - Satélites órbitas e energias',
            children: {
              '13.12.1': {
                title: 'Exemplo satélite em órbita',
              },
              '13.12.2': {
                title: 'Outro Exemplo Satélite em órbita',
              },
              '13.12.3': {
                title: 'Exemplo energia de um satélite em órbita',
              },
            },
          },
          13.13: {
            title: 'Experimentos de Gravitação Universal',
          },
          13.14: {
            title: 'Problemas com resolução de Gravitação Universal',
          },
          13.15: {
            title: 'Simulações interativas de Gravitação Universal',
          },
        },
      },
      14: {
        title: 'Mecânica dos Fluidos',
        children: {
          14.1: {
            title: 'Plano da aula',
          },
          14.2: {
            title: 'Apostila de Mecânica dos Fluidos',
          },
          14.3: {
            title: 'Videoaula - O que são fluidos ?',
          },
          14.4: {
            title: 'Videoaula - Densidade ou massa específica',
            children: {
              '14.4.1': {
                title: 'Exemplo de massa específica',
              },
            },
          },
          14.5: {
            title: 'Videoaula - Pressão',
            children: {
              '14.5.1': {
                title: 'Exemplo de pressão',
              },
            },
          },
          14.6: {
            title: 'Videoaula - Lei de Stevin Pressão Hidrostática',
            children: {
              '14.6.1': {
                title: 'Exemplo de pressão hidrostática',
              },
            },
          },
          14.7: {
            title: 'Videoaula - Medindo a pressão',
          },
          14.8: {
            title: 'Videoaula - Lei de Pascal Prensa Hidráulica',
            children: {
              '14.8.1': {
                title: 'Exemplo de prensa hidráulica',
              },
            },
          },
          14.9: {
            title: 'Videoaula - Lei de Arquimedes Empuxo',
            children: {
              '14.9.1': {
                title: 'Exemplo de Lei de Arquimedes',
              },
            },
          },
          '14.10': {
            title: 'Videoaula - Escoamento de um fluido ideal',
          },
          14.11: {
            title: 'Videoaula - Vazão e equação da continuidade',
            children: {
              '14.11.1': {
                title: 'Exemplo de Equação da continuidade',
              },
            },
          },
          14.12: {
            title: 'Videoaula - Equação de Bernoulli',
            children: {
              '14.12.1': {
                title: 'Exemplo Equação de Bernoulli furo',
              },
              '14.12.2': {
                title: 'Exemplo Equação de Bernoulli desnível',
              },
              '14.12.3': {
                title: 'Exemplo Equação de Bernoulli - Tubo de Pitot',
              },
              '14.12.4': {
                title: 'Exemplo Equação de Bernoulli - Tubo de Venturi',
              },
            },
          },
          14.13: {
            title: 'Experimentos de mecânica dos Fluidos',
          },
          14.14: {
            title: 'Problemas com resolução de Mecânica dos Fluidos',
          },
          14.15: {
            title: 'Simulações interativas de mecância dos fluidos',
          },
        },
      },
      15: {
        title: 'Oscilações',
        children: {
          15.1: {
            title: 'Plano da aula',
          },
          15.2: {
            title: 'Apostilas de Oscilações',
          },
          15.3: {
            title: 'Videoaula - Oscilações',
            children: {
              '15.3.1': {
                title: 'Exemplo de período e frequência',
              },
            },
          },
          15.4: {
            title: 'Videoaula - Movimento Harmônico Simples (MHS)',
            children: {
              '15.4.1': {
                title: 'Exemplo de MHS',
              },
            },
          },
          15.5: {
            title: 'Videoaula - Dinâmica do MHS',
            children: {
              '15.5.1': {
                title: 'Exemplo de dinâmica do MHS',
              },
            },
          },
          15.6: {
            title: 'Videoaula - MHS na vertical',
          },
          15.7: {
            title: 'Videoaula - Energia no MHS',
            children: {
              '15.7.1': {
                title: 'Exemplo energia no MHS',
              },
            },
          },
          15.9: {
            title: 'Videoaula - Pêndulo Simples',
            children: {
              '15.9.1': {
                title: 'Exemplo de pêndulo simples',
              },
            },
          },
          15.8: {
            title: 'Videoaula - MHS angular',
          },
          '15.10': {
            title: 'Videoaula - Pêndulo Físico',
          },
          15.11: {
            title: 'Videoaula - MHS como projeção de um MCU',
          },
          15.12: {
            title: 'Videoaula - Movimento Harmônico Amortecido',
            children: {
              '15.12.1': {
                title: 'Exemplo de MH amortecido',
              },
            },
          },
          15.13: {
            title: 'Videoaula - Oscilações Forçadas e ressonância',
          },
          14.14: {
            title: 'Experimentos de Oscilações',
          },
          14.15: {
            title: 'Problemas com resolução de Oscilações',
          },
          15.16: {
            title: 'Simulações interativas de Oscilações',
          },
        },
      },
      16: {
        title: 'Ondas mecânicas',
        children: {
          16.1: {
            title: 'Plano da aula',
          },
          16.2: {
            title: 'Apostila de Ondas mecânicas',
          },
          16.3: {
            title: 'Videoaula - O que são ondas?',
          },
          16.4: {
            title: 'Videoaula - Natureza das ondas',
          },
          16.5: {
            title: 'Videoaula - Tipos de ondas',
          },
          16.6: {
            title: 'Videoaula - Equação de uma onda na corda',
          },
          16.7: {
            title: 'Videoaula - Velocidade de uma onda progressiva',
            children: {
              '16.7.1': {
                title: 'Exemplo de equação de uma onda transversal',
              },
            },
          },
          16.8: {
            title: 'Videoaula - Velocidade de uma onda em uma corda',
            children: {
              '16.8.1': {
                title: 'Exemplo velocidade de propagação da onda tranversal',
              },
            },
          },
          16.9: {
            title: 'Videoaula - Potência das Ondas em uma corda',
            children: {
              '16.9.1': {
                title: 'Exemplo de potência de ondas em uma corda',
              },
            },
          },
          '16.10': {
            title: ' Videoaula - Função diferencial de uma onda',
            children: {
              '16.10.1': {
                title: 'Exemplo de função diferencial de uma onda',
              },
            },
          },
          16.11: {
            title: ' Videoaula - Superposição de pulsos',
          },
          16.12: {
            title: ' Videoaula - Interferência de Ondas de mesma amplitude',
            children: {
              '16.12.1': {
                title: 'Exemplo de interferência de duas ondas de mesma amplitude',
              },
            },
          },
          16.13: {
            title: ' Videoaula - Fasores',
            children: {
              '16.13.2': {
                title: 'Exemplo de fasores',
              },
            },
          },
          16.14: {
            title: ' Videoaula - Ondas Estacionárias',
            children: {
              '16.14.1': {
                title: 'Exemplo de ondas estacionárias',
              },
            },
          },
          16.15: {
            title: ' Videoaula - Ressonância em ondas estacionárias',
          },
          16.16: {
            title: ' Experimentos de ondas mecânicas',
          },
          16.17: {
            title: ' Problemas com resolução de Ondas mecânicas',
          },
          16.18: {
            title: ' Simulações interativas de Ondas mecânicas',
          },
        },
      },
      17: {
        title: 'Som e audição',
        children: {
          17.1: {
            title: 'Plano da aula',
          },
          17.2: {
            title: 'Apostila de Som e audição',
          },
          17.3: {
            title: 'Videoaula - O que são Ondas Sonoras ?',
          },
          17.4: {
            title: 'Videoaula - Velocidade de ondas sonoras',
            children: {
              '17.4.1': {
                title: 'Exemplo de velocidade de ondas sonoras',
              },
            },
          },
          17.5: {
            title: 'Videoaula - Ondas sonoras Progressivas',
            children: {
              '15.5.1': {
                title: 'Exemplo de ondas sonoras progressivas',
              },
            },
          },
          17.6: {
            title: 'Videoaula - Interferência de Ondas sonoras',
            children: {
              '17.6.1': {
                title: 'Exemplo de interferência sonora',
              },
            },
          },
          17.7: {
            title: 'Videoaula - Intensidade Sonora',
            children: {
              '17.7.1': {
                title: 'Exemplo de intensidade sonora',
              },
            },
          },
          17.8: {
            title: 'Videoaula - Fontes de sons musicais',
            children: {
              '17.8.1': {
                title: 'Exemplo de fontes sonoras',
              },
            },
          },
          17.9: {
            title: 'Videoaula - Batimento',
            children: {
              '17.9.1': {
                title: 'Exemplo de batimento',
              },
            },
          },
          '17.10': {
            title: 'Videoaula - Efeito Doppler',
            children: {
              '17.10.1': {
                title: 'Exemplo de efeito Doppler',
              },
            },
          },
          17.11: {
            title: 'Videoaula - Velocidades supersônicas e ondas de choque',
            children: {
              '17.11.1': {
                title: 'Exemplo de ondas de choque',
              },
            },
          },
          17.12: {
            title: 'Experimentos de Som e audição',
          },
          17.13: {
            title: 'Problemas com resolução de Som e audição',
          },
          17.14: {
            title: 'Simulações interativas de Som e audição',
          },
        },
      },
      18: {
        title: 'Temperatura, Calor e 1a Lei da termodinâmica',
        children: {
          18.1: {
            title: 'Plano da aula',
          },
          18.2: {
            title: 'Apostila de Temperatura, Calor e 1a Lei da termo',
          },
          18.3: {
            title: 'Videoaula - O que é Temperatura ?',
          },
          18.4: {
            title: 'Videoaula - Lei zero da termodinâmica',
          },
          18.5: {
            title: 'Videoaula - Kelvin',
          },
          18.6: {
            title: 'Videoaula - Celsius e Fahrenheit',
            children: {
              '18.6.1': {
                title: 'Exemplo de escalas termométricas',
              },
            },
          },
          18.7: {
            title: 'Videoaula - Dilatação Térmica',
            children: {
              '18.7.1': {
                title: 'Exemplo de dilatação térmica',
              },
            },
          },
          18.8: {
            title: 'Videoaula - Temperatura e calor',
          },
          18.9: {
            title: 'Videoaula - Absorção de calor por sólidos e líquidos',
            children: {
              '18.9.1': {
                title: 'Exemplo de trocas de calor',
              },
            },
          },
          '18.10': {
            title: 'Videoaula - Calor e trabalho',
            children: {
              '18.10.1': {
                title: 'Exemplo de trabalho de calor',
              },
            },
          },
          18.11: {
            title: 'Videoaula - 1a Lei da Termodinâmica',
          },
          18.12: {
            title: 'Videoaula - Casos especiais 1a lei da termo',
            children: {
              '18.12.1': {
                title: 'Exemplo de 1a Lei da Termodinâmica',
              },
            },
          },
          18.13: {
            title: 'Videoaula - Mecanismos de transmissão de calor',
            children: {
              '18.13.1': {
                title: 'Exemplo de radiação térmica',
              },
              '18.13.2': {
                title: 'Exemplo de condução de calor',
              },
            },
          },
          18.14: {
            title: 'Experimentos de Temperatura, calor e 1a Lei da termodinâmica',
          },
          18.15: {
            title: 'Problemas com resolução de Temperatura, calor e 1a lei',
          },
          18.16: {
            title: 'Simulações interativas de Temperatura, Calor e 1a Lei da termo',
          },
        },
      },
      19: {
        title: 'Teoria Cinética dos gases',
        children: {
          19.1: {
            title: 'Plano da aula',
          },
          19.2: {
            title: 'Apostila Teoria Cinética dos gases',
          },
          19.3: {
            title: 'Videoaula - Teoria cinética dos gases',
          },
          19.4: {
            title: 'Videoaula - Número de Avogadro',
            children: {
              '19.4.1': {
                title: 'Exemplo de Número de Avogadro',
              },
            },
          },
          19.5: {
            title: 'Videoaula - Gases ideais',
            children: {
              '19.5.1': {
                title: 'Exemplo de gases ideais',
              },
            },
          },
          19.6: {
            title: 'Videoaula - pressão temperatura e velocidade quadrática média',
            children: {
              '19.6.1': {
                title: 'Exemplo de velocidade quadrática média',
              },
            },
          },
          19.7: {
            title: 'Videoaula - Energia cinética média',
            children: {
              '19.7.1': {
                title: 'Exemplo de Energia Cinética Média',
              },
            },
          },
          19.8: {
            title: 'Videoaula - Lei de distribuição de velocidades de Maxwell',
            children: {
              '19.8.1': {
                title: 'Exemplo de distribuição de Maxwell',
              },
            },
          },
          19.9: {
            title: 'Videoaula - Livre caminho médio',
            children: {
              '19.9.1': {
                title: 'Exemplo de Livre Caminho Médio',
              },
            },
          },
          '19.10': {
            title: 'Videoaula - Calor específico molar',
            children: {
              '19.10.1': {
                title: 'Exemplo de Calor específico molar',
              },
              '19.10.2': {
                title: 'Outro Exemplo de Calor específico molar',
              },
            },
          },
          19.11: {
            title: 'Videoaula - Graus de liberdade',
          },
          19.12: {
            title: 'Videoaula - Efeitos Quânticos',
          },
          19.13: {
            title: 'Videoaula - Transformação adiabática',
          },
          19.14: {
            title: 'Experimentos de Teoria Cinética dos gases',
            children: {
              '19.14.1': {
                title: 'Exemplo de Transformação Adiabática',
              },
            },
          },
          19.15: {
            title: 'Problemas com resolução de Teoria cinéticas dos gases',
          },
          19.16: {
            title: 'Simulações interativas de Teoria Cinética dos gases',
          },
        },
      },
      20: {
        title: 'Entropia e 2a Lei da Termodinâmica',
        children: {
          20.1: {
            title: 'Plano da aula',
          },
          20.2: {
            title: 'Apostila de Entropia e 2a Lei da Termodinâmica',
          },
          20.3: {
            title: 'Videoaula - O que é entropia ? Processos reversíveis e irreversíveis',
          },
          20.4: {
            title: 'Videoaula - Variação de Entropia',
            children: {
              '20.4.1': {
                title: 'Exemplo de variação de entropia',
              },
            },
          },
          20.5: {
            title: 'Videoaula - Segunda lei da termodinâmica e entropia',
            children: {
              '20.5.1': {
                title: 'Exemplos de 2a Lei da Termodinâmica',
              },
            },
          },
          20.6: {
            title: 'Videoaula - Máquinas térmicas',
          },
          20.7: {
            title: 'Videoaula - Máquina Frigorífica',
            children: {
              '20.7.1': {
                title: 'Exemplo de máquina frigorífica',
              },
            },
          },
          20.8: {
            title: 'Videoaula - Ciclo de Carnot',
            children: {
              '20.8.1': {
                title: 'Exemplo de máquina de Carnot',
              },
            },
          },
          20.9: {
            title: 'Videoaula - Visão estatística da Entropia',
            children: {
              '20.9.1': {
                title: 'Exemplo de visão estatística da entropia',
              },
            },
          },
          '20.10': {
            title: 'Experimentos de Entropia e 2a Lei da termodinâmica',
          },
          20.11: {
            title: 'Problemas com resolução de Entropia e 2a lei',
          },
          20.12: {
            title: 'Simulações interativas de Entropia e 2a Lei da Termodinâmica',
          },
        },
      },
    },
  },
  3: {
    title: 'Física 3 - Eletricidade - Eletrostática, Eletrodinâmica e Eletromagnetismo',
    children: {
      21: {
        title: 'Cargas elétricas e Lei de Coulomb',
        children: {
          21.1: {
            title: 'Plano da aula',
          },
          21.2: {
            title: 'Apostila de Cargas elétricas e Lei de Coulomb',
          },
          21.3: {
            title: 'Videoaula - Introdução à eletricidade',
          },
          21.4: {
            title: 'Videoaula - Cargas elétricas',
          },
          21.5: {
            title: 'Videoaula - Condutores e isolantes',
          },
          21.6: {
            title: 'Experimentos de processos de eletrização',
          },
          21.7: {
            title: 'Simulação interativa de eletrização por atrito',
          },
          21.8: {
            title: 'Videoaula - Lei de Coulomb',
            children: {
              '21.8.1': {
                title: 'Exemplo de Lei de Coulomb',
              },
            },
          },
          21.9: {
            title: 'Experimentos sobre a Lei de Coulomb',
          },
          '21.10': {
            title: 'Simulação interativa da Lei de Coulomb',
          },
          21.11: {
            title: 'Problemas com resolução de Cargas elétricas e Lei de Coulomb',
          },
        },
      },
      22: {
        title: 'Campo Elétrico',
        children: {
          22.1: {
            title: 'Plano da aula',
          },
          22.2: {
            title: 'Apostila de Campo Elétrico',
          },
          22.3: {
            title: 'Videoaula - Definição Física de campo elétrico',
          },
          22.4: {
            title: 'Videoaula - Definição matemática de campo elétrico',
          },
          22.5: {
            title: 'Videoaula - Linhas de campo elétrico',
          },
          22.6: {
            title: 'Videoaula - Campo elétrico criado por cargas puntiformes',
            children: {
              '22.6.1': {
                title: 'Exemplo de campo elétrico de carga puntiforme',
              },
            },
          },
          22.7: {
            title: 'Videoaula - Campo elétrico criado por um dipolo elétrico',
          },
          22.8: {
            title: 'Videoaula - Campo elétrico criado por uma linha de carga',
            children: {
              '22.8.1': {
                title: 'Exemplo de campo elétrico criado por uma linha de carga',
              },
              '22.8.2': {
                title: 'Exemplo de campo elétrico criado por uma linha de carga 2',
              },
            },
          },
          22.9: {
            title: 'Videoaula - Campo elétrico criado por um disco carregado',
            children: {
              '22.9.1': {
                title: 'Exemplo de campo elétrico criado por um disco carregado',
              },
            },
          },
          '22.10': {
            title: 'Videoaula - Carga puntiforme em um campo elétrico',
          },
          22.11: {
            title: 'Experimentos de campo elétrico',
          },
          22.12: {
            title: 'Problemas com resolução de Campo Elétrico',
          },
          22.13: {
            title: 'Simulações interativas de Campo Elétrico',
          },
        },
      },
      23: {
        title: 'Lei de Gauss',
        children: {
          23.1: {
            title: 'Plano da aula',
          },
          23.2: {
            title: 'Apostila de Lei de Gauss',
          },
          23.3: {
            title: 'Videoaula - Conceito de Lei de Gauss',
          },
          23.4: {
            title: 'Videoaula - Fluxo',
          },
          23.5: {
            title: 'Videoaula - Fluxo de campo elétrico',
            children: {
              '23.5.1': {
                title: 'Exemplo de Fluxo de campo elétrico',
              },
            },
          },
          23.6: {
            title: 'Videoaula - Lei de Gauss',
          },
          23.7: {
            title: 'Videoaula - Lei de Gaus e Lei de Coulomb',
          },
          23.8: {
            title: 'Videoaula - Condutor carregado',
          },
          23.9: {
            title: 'Videoaula - Aplicação da lei de Gauss Simetria cilíndrica',
            children: {
              '23.9.1': {
                title: 'Exemplo de lei de Gauss Simetria cilíndrica',
              },
              '23.9.2': {
                title: 'Exemplo de lei de Gauss Simetria cilíndrica 2',
              },
              '23.9.3': {
                title: 'Exemplo de lei de Gauss Simetria cilíndrica 3',
              },
            },
          },
          '23.10': {
            title: 'Videoaula - Aplicação da lei de Gauss Simetria planar',
            children: {
              '23.10.1': {
                title: 'Exemplo de lei de Gauss Simetria planar',
              },
            },
          },
          23.11: {
            title: 'Videoaula - Aplicação da lei de Gauss Simetria Esférica',
          },
          23.12: {
            title: 'Simulações interativas de  Lei de Gauss',
          },
          23.13: {
            title: 'Experimentos de Lei de Gauss',
          },
          23.14: {
            title: 'Problemas com resolução de Lei de Gauss',
          },
        },
      },
      24: {
        title: 'Potencial elétrico',
        children: {
          24.1: {
            title: 'Plano da aula',
          },
          24.2: {
            title: 'Apostila de Potencial Elétrico',
          },
          24.3: {
            title: 'Videoaula - Potencial elétrico introdução',
          },
          24.4: {
            title: 'Videoaula - Energia potencial elétrica',
            children: {
              '24.4.1': {
                title: 'Exemplo de Energia potencial elétrica',
              },
            },
          },
          24.5: {
            title: 'Videoaula - Potencial Elétrico',
            children: {
              '24.5.1': {
                title: 'Exemplo de Potencial Elétrico',
              },
            },
          },
          24.6: {
            title: 'Videoaula - Superfícies equipotenciais',
          },
          24.7: {
            title: 'Videoaula - Cálculo do potencial a partir do campo',
          },
          24.8: {
            title: 'Videoaula - Potencial criado por uma carga puntiforme',
            children: {
              '24.8.1': {
                title: 'Exemplo Potencial criado por uma carga puntiforme',
              },
            },
          },
          24.9: {
            title: 'Videoaula - Potencial produzido por várias cargas pontuais',
          },
          '24.10': {
            title: 'Videoaula - Potencial produzido por um dipolo elétrico',
          },
          24.11: {
            title: 'Videoaula - Potencial produzido por uma distribuição uniforme de cargas',
          },
          24.12: {
            title: 'Videoaula - Cálculo do campo elétrico a partir do potencial',
            children: {
              '24.12.1': {
                title: 'Exemplo de Cálculo do campo elétrico a partir do potencial',
              },
            },
          },
          24.13: {
            title: 'Videoaula - Energia potencial de um sistema de partículas',
            children: {
              '24.13.1': {
                title: 'Exemplo de Energia potencial de um sistema de partículas',
              },
            },
          },
          24.14: {
            title: 'Videoaula - Potencial de um condutor carregado',
            children: {
              '24.14.1': {
                title: 'Exemplo de Potencial de um condutor carregado',
              },
            },
          },
          24.15: {
            title: 'Simulações interativas de Potencial elétrico',
          },
          24.16: {
            title: 'Experimentos de Potencial Elétrico',
          },
          24.17: {
            title: 'Problemas com resolução de Potencial elétrico',
          },
        },
      },
      25: {
        title: 'Capacitores',
        children: {
          25.1: {
            title: 'Plano da aula',
          },
          25.2: {
            title: 'Apostila de Capacitores',
          },
          25.3: {
            title: 'Videoaula - Capacitores - introdução',
          },
          25.4: {
            title: 'Videoaula - Capacitância',
            children: {
              '25.4.1': {
                title: 'Exemplo de Capacitância',
              },
            },
          },
          25.5: {
            title: 'Videoaula - Cálculo da capacitância',
            children: {
              '25.5.1': {
                title: 'Exemplo de Cálculo da capacitância',
              },
            },
          },
          25.6: {
            title: 'Videoaula - Associação de capacitores',
            children: {
              '25.6.1': {
                title: 'Exemplo de Associação de capacitores',
              },
              '25.6.2': {
                title: 'Exemplo de Associação de capacitores 2',
              },
            },
          },
          25.7: {
            title: 'Videoaula - Energia armazenada em um campo',
          },
          25.8: {
            title: 'Videoaula - Capacitor com dielétrico',
            children: {
              '25.8.1': {
                title: 'Exemplo de Capacitor com dielétrico',
              },
              '25.8.2': {
                title: 'Exemplo de Capacitor com dielétrico 2',
              },
            },
          },
          25.9: {
            title: 'Videoaula - Dielétrico uma visão molecular',
          },
          '25.10': {
            title: 'Videoaula - Dielétricos e Lei de Gauss',
            children: {
              '25.10.1': {
                title: 'Exemplo de Dielétricos e Lei de Gauss',
              },
            },
          },
          25.11: {
            title: 'Simulações interativas de Capacitores',
          },
          25.12: {
            title: 'Experimentos de Capacitores',
          },
          25.13: {
            title: 'Problemas com resolução de Capacitores',
          },
        },
      },
      26: {
        title: 'Corrente e resistência elétrica',
        children: {
          26.1: {
            title: 'Plano da aula',
          },
          26.2: {
            title: 'Apostila de Corrente e resistência elétrica',
          },
          26.3: {
            title: 'Videoaula - Corrente e resistência elétrica - Introdução',
          },
          26.4: {
            title: 'Videoaula - Intensidade de corrente elétrica',
            children: {
              '26.4.1': {
                title: 'Exemplo de Intensidade de corrente elétrica',
              },
            },
          },
          26.5: {
            title: 'Videoaula - Densidade de corrente',
            children: {
              '26.5.1': {
                title: 'Exemplo de Densidade de corrente',
              },
            },
          },
          26.7: {
            title: 'Videoaula - Lei de Ohm',
          },
          26.8: {
            title: 'Videoaula - Resistência e resistividade',
            children: {
              '26.8.1': {
                title: 'Exemplo de Resistência e resistividade',
              },
            },
          },
          26.9: {
            title: 'Videoaula - Potência em circuitos elétricos',
            children: {
              '26.9.1': {
                title: 'Exemplo de Potência em circuitos elétricos',
              },
            },
          },
          '26.10': {
            title: 'Simulações interativas de Corrente e resistência elétrica',
          },
          26.11: {
            title: 'Experimentos de Corrente e resistência elétrica',
          },
          26.12: {
            title: 'Problemas com resolução de Capacitores',
          },
        },
      },
      27: {
        title: 'Circuitos Elétricos',
        children: {
          27.1: {
            title: 'Plano da aula',
          },
          27.2: {
            title: 'Apostila de Circuitos Elétricos',
          },
          27.3: {
            title: 'Videoaula - Força eletromotriz',
          },
          27.4: {
            title: 'Videoaula - Circuito de malha única ideal',
          },
          27.5: {
            title: 'Videoaula - Circuito de malha única associação de resistores em série',
          },
          27.6: {
            title: 'Videoaula - Circuito malha única real e potência',
          },
          27.7: {
            title: 'Videoaula - Diferença de potencial entre dois pontos de um circuito',
            children: {
              '27.7.1': {
                title: 'Exemplo de Diferença de potencial entre dois pontos de um circuito',
              },
            },
          },
          27.8: {
            title: 'Videoaula - Circuito de mais de uma malha Leis de Kirchhoff',
          },
          27.9: {
            title: 'Videoaula - Circuito de mais de uma malha Resistores em paralelo',
            children: {
              '27.9.1': {
                title: 'Exemplo de Circuito de mais de uma malha Resistores em paralelo',
              },
            },
          },
          '27.10': {
            title:
              'Videoaula - Circuito de mais de uma malha Gerados ligados em série e em paralelo',
            children: {
              '27.10.1': {
                title:
                  'Exemplo de Circuito de mais de uma malha Gerados ligados em série e em paralelo',
              },
            },
          },
          27.11: {
            title: 'Videoaula - Voltímetros e amperímetros reais',
          },
          27.12: {
            title: 'Videoaula - Circuito RC',
            children: {
              '27.12.1': {
                title: 'Exemplo de Circuito RC',
              },
            },
          },
          27.13: {
            title: 'Simulações interativas de Circuitos Elétricos',
          },
          27.14: {
            title: 'Problemas com resolução de Circuitos Elétricos',
          },
        },
      },
      28: {
        title: 'Força magnética',
        children: {
          28.1: {
            title: 'Plano da aula',
          },
          28.2: {
            title: 'Apostila de Força magnética',
          },
          28.3: {
            title: 'Videoaula - Introdução ao campo magnético',
          },
          28.4: {
            title: 'Videoaula - Definição de campo magnético B',
            children: {
              '28.4.1': {
                title: 'Exemplo de Definição de campo magnético',
              },
              '28.4.2': {
                title: 'Exemplo de Definição de campo magnético 2',
              },
            },
          },
          28.5: {
            title: 'Videoaula - Linhas de campo magnético',
          },
          28.6: {
            title: 'Videoaula - Campos cruzados a descoberta do elétron',
            children: {
              '28.6.1': {
                title: 'Exemplo de Campos cruzados',
              },
              '28.6.2': {
                title: 'Exemplo de Campos cruzados 2',
              },
            },
          },
          28.7: {
            title: 'Videoaula - Campos cruzados O efeito Hall',
            children: {
              '28.7.1': {
                title: 'Exemplo de Campos cruzados O efeito Hall',
              },
            },
          },
          28.8: {
            title: 'Videoaula - Uma partícula carregada em movimento circular',
            children: {
              '28.8.1': {
                title: 'Exemplo de Uma partícula carregada em movimento circular',
              },
            },
          },
          28.9: {
            title: 'Videoaula - Força magnética sobre um fio imerso em um campo magnético',
            children: {
              '28.9.1': {
                title: 'Exemplo de Força magnética sobre um fio imerso em um campo magnético',
              },
            },
          },
          '28.10': {
            title: 'Videoaula - Torque em uma espira que circula corrente',
          },
          28.11: {
            title: 'Videoaula - Momento dipolar magnético',
            children: {
              '28.11.1': {
                title: 'Exemplo de  Momento dipolar magnético',
              },
            },
          },
          28.12: {
            title: 'Simulações interativas de Força magnética',
          },
          28.13: {
            title: 'Experimentos de Força magnética',
          },
          28.14: {
            title: 'Problemas com resolução de Força magnética',
          },
        },
      },
      29: {
        title: 'Campos magnéticos criados por corrente elétrica',
        children: {
          29.1: {
            title: 'Plano da aula',
          },
          29.2: {
            title: 'Apostila de Campos magnéticos criados por corrente elétrica',
          },
          29.3: {
            title: 'Videoaula - Campo magnético criado por corrente',
          },
          29.4: {
            title: 'Videoaula - Cálculo do campo magnético em um fio reto e longo',
            children: {
              '29.4.1': {
                title: 'Exemplo de Cálculo do campo magnético em um fio reto e longo',
              },
              '29.4.2': {
                title: 'Exemplo de Cálculo do campo magnético em um fio reto e longo 2',
              },
            },
          },
          29.5: {
            title: 'Videoaula - Cálculo do campo magnético em um arco de circunferência',
            children: {
              '29.5.1': {
                title: 'Exemplo de Cálculo do campo magnético em um arco de circunferência',
              },
            },
          },
          29.6: {
            title: 'Videoaula - Forças entre duas correntes paralelas',
          },
          29.7: {
            title: 'Videoaula - Lei de Ampère',
          },
          29.8: {
            title: 'Videoaula - Solenoide e toroide',
            children: {
              '29.8.1': {
                title: 'Exemplo de Solenoide e toroide',
              },
            },
          },
          29.9: {
            title: 'Videoaula - Bobina percorrida por corrente como momento de dipolo magnético',
            children: {
              '29.9.1': {
                title: 'Exemplo de Bobina percorrida por corrente como momento de dipolo magnético',
              },
            },
          },
          '29.10': {
            title: 'Simulações interativas de Campos magnéticos criados por corrente elétrica',
          },
          29.11: {
            title: 'Experimentos de Campos magnéticos criados por corrente elétrica',
          },
          29.12: {
            title: 'Problemas com resolução de Campos magnéticos criados por corrente elétrica',
          },
        },
      },
      30: {
        title: 'Indução Eletromagnética e indutância',
        children: {
          30.1: {
            title: 'Plano da aula',
          },
          30.2: {
            title: 'Apostilas de Indução Eletromagnética e indutância',
          },
          30.3: {
            title: 'Videoaula - Indução eletromagnética',
          },
          30.4: {
            title: 'Videoaula - Lei da indução de Faraday',
            children: {
              '30.4.1': {
                title: 'Exemplo de Lei da indução de Faraday',
              },
            },
          },
          30.5: {
            title: 'Videoaula - Lei de Lenz',
          },
          30.6: {
            title: 'Videoaula - Indução e transferência de energia',
            children: {
              '30.6.1': {
                title: 'Exemplo de Indução e transferência de energia',
              },
              '30.6.2': {
                title: 'Exemplo de Indução e transferência de energia 2',
              },
            },
          },
          30.7: {
            title: 'Videoaula - Campos elétricos induzidos',
          },
          30.8: {
            title: 'Videoaula - Indutores e autoindução',
            children: {
              '30.8.1': {
                title: 'Exemplo de Indutores e autoindução',
              },
            },
          },
          30.9: {
            title: 'Videoaula - Indutores como elementos de circuito - fem autoinduzida',
            children: {
              '30.6.1': {
                title: 'Exemplo de Indução e transferência de energia',
              },
              '30.6.2': {
                title: 'Exemplo de Indução e transferência de energia 2',
              },
            },
          },
          '30.10': {
            title: 'Videoaula - Associação de indutores',
          },
          30.11: {
            title: 'Videoaula - Circuito RL',
            children: {
              '30.11.1': {
                title: 'Exemplo de Circuito RL',
              },
            },
          },
          30.12: {
            title: 'Videoaula - Energia armazenada em um campo magnético',
          },
          30.13: {
            title: 'Videoaula - Densidade de energia de um campo magnético',
          },
          30.14: {
            title: 'Simulações interativas de Indução Eletromagnética e indutância',
          },
          30.15: {
            title: 'Experimentos de Indução Eletromagnética e indutância',
          },
          30.16: {
            title: 'Problemas com resolução de Indução Eletromagnética e indutância',
          },
        },
      },
      31: {
        title: 'Corrente alternada',
        children: {
          31.1: {
            title: 'Plano da aula',
          },
          31.2: {
            title: 'Apostila de Corrente alternada',
          },
          31.3: {
            title: 'Videoaula - Corrente alternada',
          },
          31.4: {
            title: 'Videoaula - Análise qualitativa do circuito LC',
          },
          31.5: {
            title: 'Videoaula - Análise quantitativa do circuito LC',
            children: {
              '31.5.1': {
                title: 'Exemplo de Análise quantitativa do circuito LC',
              },
              '31.5.2': {
                title: 'Exemplo de Análise quantitativa do circuito LC 2',
              },
            },
          },
          31.6: {
            title: 'Videoaula - Circuito RLC',
          },
          31.7: {
            title: 'Videoaula - Oscilações forçadas',
          },
          31.8: {
            title: 'Videoaula - Três circuitos simples Carga resistiva',
          },
          31.9: {
            title: 'Videoaula - Três circuitos simples Carga capacitiva',
          },
          '31.10': {
            title: 'Videoaula - Três circuitos simples carga indutiva',
          },
          31.11: {
            title: 'Videoaula - Circuito RLC em Série',
            children: {
              '31.11.1': {
                title: 'Exemplo de Circuito RLC',
              },
              '31.11.2': {
                title: 'Exemplo de Circuito RLC 2',
              },
            },
          },
          31.12: {
            title: 'Videoaula - Circuito RLC ressonância',
          },
          31.13: {
            title: 'Videoaula - Potência em circuitos de corrente alternada',
            children: {
              '31.13.1': {
                title: 'Exemplo de Potência em circuitos de corrente alternada',
              },
            },
          },
          31.14: {
            title: 'Videoaula - Transformadores',
            children: {
              '31.14.1': {
                title: 'Exemplo de Transformadores',
              },
            },
          },
          31.15: {
            title: 'Videoaula - Rede trifásica',
          },
          31.16: {
            title: 'Simulações interativas de Corrente alternada',
          },
          31.17: {
            title: 'Experimentos de Corrente alternada',
          },
          31.18: {
            title: 'Problemas com resolução de Corrente alternada',
          },
        },
      },
      32: {
        title: 'Equações de Maxwell e magnetismo na natureza',
        children: {
          32.1: {
            title: 'Plano da aula',
          },
          32.2: {
            title: 'Apostila de Equações de Maxwell e magnetismo na natureza',
          },
          32.3: {
            title: 'Videoaula - Ondas eletromagnéticas',
          },
          32.4: {
            title: 'Videoaula - Lei de Gauss para campos magnéticos',
          },
          32.5: {
            title: 'Videoaula - Campos magnéticos induzidos',
          },
          32.6: {
            title: 'Videoaula - Corrente de deslocamento',
          },
          32.7: {
            title: 'Videoaula - Leis de Maxwell',
          },
          32.8: {
            title: 'Videoaula - Magnetismo terrestre',
          },
          32.9: {
            title: 'Videoaula - Magnetismo e o elétron',
          },
          '32.10': {
            title: 'Videoaula - Propriedades magnéticas dos materiais',
          },
          32.11: {
            title: 'Videoaula - Diamagnetismo',
          },
          32.12: {
            title: 'Videoaula - Paramagnetismo',
          },
          32.13: {
            title: 'Videoaula - Ferromagnetismo',
          },
          32.14: {
            title: 'Simulações de Equações de Maxwell e magnetismo na natureza',
          },
          32.15: {
            title: 'Experimentos de equações de Maxwell e magnetismo na natureza',
          },
        },
      },
    },
  },
};

export default LeaningPlan;
