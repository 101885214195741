
















































import Vue from 'vue';
import LeaningPlan from '@/metadata/LearningPlan';
import LeaningPlanType from '@/types/LearningPlan';

interface dataType {
  LeaningPlan: LeaningPlanType,
  open: Record<string, boolean>
}

export default Vue.extend({
  name: 'PlatformFaqSection',
  data(): dataType {
    return {
      open: { 1: true },
      LeaningPlan,
    };
  },
  methods: {
    toggleFaq(i: string | number) {
      this.open = {
        ...this.open,
        [i]: !this.open[i],
      };
    },
  },
});
